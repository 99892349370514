import React from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import { formatMoney } from '../../helpers';
import { debtorTypeToColor, debtorTypeToSpanish } from '../collection/helpers/debtors';
import { StatisticItemStack } from '../../components/stacks';
import { StatisticCard } from '../../components/cards';

const DebtorOrderingSummaryStatisticsGrid = ({
  debtorType,
  currentAmount,
  debtAmount,
  loading,
  currentValid,
}) => (
  <Grid container spacing={3} paddingX={3} sx={{ height: '100%' }}>
    <Grid item xs={12} sm={4}>
      <StatisticCard>
        <StatisticItemStack
          title="Tipo de deudor"
          value={debtorTypeToSpanish(debtorType)}
          valueProps={{ color: debtorTypeToColor(debtorType) }}
        />
      </StatisticCard>
    </Grid>
    <Grid item xs={12} sm={4}>
      <StatisticCard>
        <StatisticItemStack
          title="Deuda pendiente"
          value={formatMoney(currentAmount)}
          loading={loading}
        />
      </StatisticCard>
    </Grid>
    <Grid item xs={12} sm={4}>
      <StatisticCard>
        <StatisticItemStack
          title="Documentos vencidos"
          value={formatMoney(debtAmount)}
          loading={loading}
        />
        <StatisticItemStack
          title="Documentos vigentes"
          value={formatMoney(currentValid)}
          loading={loading}
        />
      </StatisticCard>
    </Grid>
  </Grid>
);

DebtorOrderingSummaryStatisticsGrid.propTypes = {
  debtorType: PropTypes.string.isRequired,
  currentAmount: PropTypes.number,
  debtAmount: PropTypes.number,
  loading: PropTypes.bool,
  currentValid: PropTypes.number,
};

DebtorOrderingSummaryStatisticsGrid.defaultProps = {
  currentAmount: null,
  debtAmount: null,
  loading: false,
  currentValid: 0,
};

export default DebtorOrderingSummaryStatisticsGrid;
