import theme from '../../../theme';

export const debtorTypeToColor = (debtorType) => {
  if (debtorType === 'GOOD' || debtorType === 'VERY_GOOD') {
    return theme.palette.success.main;
  }
  if (debtorType === 'IN_PROBLEMS' || debtorType === 'IN_SEVERE_PROBLEMS') {
    return theme.palette.error.main;
  }
  if (debtorType === 'BAD_LATE_PAYER' || debtorType === 'NEW') {
    return theme.palette.warning.main;
  }
  return '';
};

export const debtorTypeToSpanish = (debtorType) => {
  switch (debtorType) {
    case 'VERY_GOOD':
      return 'Cliente muy bueno';
    case 'NEW':
      return 'Cliente nuevo';
    case 'GOOD':
      return 'Cliente bueno';
    case 'IN_PROBLEMS':
      return 'Cliente en problemas';
    case 'BAD_LATE_PAYER':
      return 'Cliente malo que paga tarde';
    case 'IN_SEVERE_PROBLEMS':
      return 'Cliente en problemas severos';
    default:
      return '';
  }
};
